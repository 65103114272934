import React from "react";
import {
  Button,
  CardBody,
  Dialog,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";

export const SyncTables = ({
  synchedData,
  syncTab,
  setSyncTab,
  open,
  closeModal,
  setIsSynchedDataViewed,
  setOpen
}) => {
  if (!synchedData) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      style={{
        width: "90vw",
        height: "60vh",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "auto",
      }}
    >
      <CardBody>
        <Tabs value={syncTab} key={syncTab}>
          <TabsHeader>
            <Tab value="success" onClick={() => setSyncTab("success")}>
              Synched Data Items
            </Tab>
            <Tab value="failed" onClick={() => setSyncTab("failed")}>
              Failed Data Items
            </Tab>
          </TabsHeader>

          <TabsBody>
            <TabPanel value="success">
              <CardBody>
                <Typography variant="h6" color="black" className=" mb-4">
                  Successfully Synced Items
                </Typography>
                <RenderTable
                  data={synchedData?.table_data["successful_items"]}
                />
              </CardBody>
            </TabPanel>

            <TabPanel value="failed">
              <CardBody>
                <Typography variant="h6" color="black" className=" mb-4">
                  Items Failed to Sync
                </Typography>
                <RenderTable data={synchedData?.table_data["failed_items"]} />
              </CardBody>
            </TabPanel>
          </TabsBody>
        </Tabs>

        <div className=" flex w-full justify-end items-end">
          <Button className=" mr-10" color="red" onClick={() => {
              setIsSynchedDataViewed(true); // Set the flag to true
              setOpen(false); // Close the modal
            }}>
            View Details
          </Button>
          <Button className=" mr-10" color="red" onClick={closeModal}>
            Close
          </Button>
        </div>
      </CardBody>
    </Dialog>
  );
};

export default SyncTables;

export const RenderTable = ({ data }) => {
  // Function to render the field values except for 'response' column
  const renderField = (fieldValue) => {
    if (typeof fieldValue === "object" && fieldValue !== null) {
      return JSON.stringify(fieldValue);
    }
    return fieldValue;
  };

  // Filter out the 'response' column from the keys of the data
  const filteredKeys = data[0]
    ? Object.keys(data[0]).filter((key) => key !== "response")
    : [];

  // Check if the data array is empty
  if (data.length === 0) {
    return <p className="text-center text-gray-500">No data available.</p>;
  }

  return (
    <div className="overflow-x-auto overflow-y-auto max-h-96">
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            {filteredKeys.map((header) => (
              <th
                key={header}
                className="py-1 px-2 border-b border-gray-200 bg-gray-100 text-left sticky top-0 bg-white"
              >
                {header.charAt(0).toUpperCase() + header.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {filteredKeys.map((field) => (
                <td key={field} className="py-1 px-2 border-b border-gray-200">
                  {renderField(item[field])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
